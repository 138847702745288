import { render, staticRenderFns } from "./ScrumBoardImage.vue?vue&type=template&id=5fbf6747&scoped=true&functional=true"
var script = {}
import style0 from "./ScrumBoardImage.vue?vue&type=style&index=0&id=5fbf6747&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "5fbf6747",
  null
  
)

export default component.exports